<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="lump">
            <div class="notice_list_li">
              <div class="notice_list_ttl report">
                <div class="left">
                  <span class="cate sec_color">{{
                    kind && $ConstCode.REPORT_TYPE[kind].name
                  }}</span>
                  <span>{{ contents }}</span>
                </div>
                <div class="right"></div>
              </div>
              <div class="notice_list_con">
                <div class="left">
                  <div class="date">{{ regDt }}</div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="notice_detail_con">
              <div class="editor">
                <div>{{ contents }}</div>
              </div>
              <div v-if="!$Util.isEmpty(imgList)" class="upload_file">
                <span>첨부파일</span>
                <a
                  v-for="(link, index) in imgList"
                  :key="index"
                  class="upload_file_name"
                  :href="link"
                  :download="getFileName(link)"
                  >{{ getFileName(link) }}</a
                >
              </div>
            </div>
          </div>
          <div class="lump" v-if="!$Util.isEmpty(reply)">
            <div class="notice_list_li">
              <div class="notice_list_ttl">
                <div class="left">
                  <span>회원님의 신고에 대한 답변드립니다.</span>
                </div>
                <div class="right"></div>
              </div>
              <div class="notice_list_con">
                <div class="left">
                  <div class="answer">
                    <div class="editor">
                      <div v-html="reply"></div>
                    </div>
                  </div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <div class="empty_wrap" v-else>
            <div class="empty">
              <div>
                아직 등록된 답변내용이 없습니다.
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnDelete(item)">삭제</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'cs-report-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
