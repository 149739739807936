import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    seq: route.query.seq,
    kind: '',
    contents: '',
    imgList: [],
    status: '0',
    reply: '',
    regDt: ''
  })
  const fnDetail = async () => {
    const res = await proxy.$CustomerSvc.postCustomerReportDetail(route.query)
    if (res.resultCode === '0000') {
      state.kind = res.kind
      state.contents = res.contents
      state.imgList = res.imgList
      state.status = res.status
      state.reply = res.reply
      state.regDt = res.regDt
    }
  }

  const fnDelete = async () => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {
      seq: state.seq
    }
    const res = await proxy.$CustomerSvc.postCustomerReportDelete(params)
    if (res.resultCode === '0000') {
      alert(res.resultMsg)
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }

  const getFileName = url => url.split('/')[url.split('/').length - 1]

  const init = async () => {
    await fnDetail()
  }
  /** init **/
  init()
  return { ...toRefs(state), fnDetail, fnDelete, getFileName }
}
